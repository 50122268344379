.App {}


/*/  Pages  /*/
.App-page {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}


/*/  Forms  /*/
.App-form {
  display: flex;
  flex-flow: column;
  max-width: 22em;
}


.previews img {
  max-height: 96px;
  max-width: 128px;
  width: auto;
  height: auto;
  display: block;
}